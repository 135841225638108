import React from 'react'
import { Layout, Stack, Main } from '@layout'
import PageTitle from '@components/PageTitle'
import Divider from '@components/Divider'
import Seo from '@widgets/Seo'

export default props => (
  <Layout {...props}>
    <Seo title='Note Legali' />
    <Divider />
    <Stack>
      <Main>
        <PageTitle header="Note Legali"/>
        <Divider />
        <h2>Note Legali</h2>
        <p>Il presente sito web www.criptosos.it è gestito dal (“Titolare del Sito”). Il Sito non rappresenta una testata giornalistica in quanto viene aggiornato <strong>senza alcuna periodicità regolare</strong>. Esso pertanto non costituisce “prodotto editoriale” ai sensi della Legge 7 marzo 2001, n. 62, né tanto meno ad esso si applicano le disposizioni previste per la stampa, ivi incluse le norme di cui alla Legge 8 febbraio 1948, n. 47. L’accesso e la navigazione sul Sito da parte degli utenti sono <strong>gratuiti</strong> e costituiscono accettazione dei termini e delle condizioni delle presenti note legali. Nel caso in cui l’utente non intenda accettare le presente condizioni, è sufficiente che si astenga dal navigare sulle pagine del Sito.</p>
        <h3>Limitazione di responsabilità</h3>
        <p>Il Titolare del Sito&nbsp;non assume alcuna responsabilità in merito ad eventuali problemi che possano insorgere per effetto dell’utilizzazione del Sito&nbsp;e/o di siti esterni ai quali è possibile accedere tramite eventuali collegamenti presenti all’interno del Sito. I&nbsp;<strong>collegamenti a siti esterni</strong>&nbsp;presenti nel Sito&nbsp;sono forniti come semplice servizio agli utenti, con esclusione di ogni responsabilità sulla correttezza e sulla completezza dell’insieme dei collegamenti indicati. L’indicazione dei collegamenti non implica da parte del Titolare del Sito&nbsp;alcuna approvazione e/o condivisione di responsabilità in relazione alla legittimità, alla completezza ed alla correttezza delle informazioni contenute nei siti indicati. Il Titolare del Sito, salvo diverse indicazioni di legge, non potrà essere ritenuto in alcun modo responsabile dei&nbsp;<strong>danni</strong>&nbsp;di qualsiasi natura causati direttamente o indirettamente dall’accesso al Sito, dall’ impossibilità di accedervi, dall’affidamento alle notizie in esso contenute e/o dal loro utilizzo.</p>
        <h3>Diritto d’autore e Copyright</h3>
        <p>Tutti i contenuti del Sito, compresi testi, documenti, marchi, loghi, immagini, grafica, la loro disposizione ed i loro adattamenti sono protetti dalla normativa sul diritto d’autore e dalla normativa a tutela dei marchi nonché sono coperti da&nbsp;<strong>Copyright.</strong>&nbsp;Il Sito&nbsp;può altresì contenere immagini, video, articoli, documenti, loghi e marchi di terze parti le quali hanno espressamente autorizzato il Titolare del Sito&nbsp;alla loro pubblicazione all’interno del presente sito web e/o sono stati acquisiti e pubblicati dal Titolare del Sito&nbsp;in buona fede quale materiale ritenuto di pubblico dominio. Anche il suddetto materiale di terze parti è protetto dalla normativa sul diritto d’autore e dalla normativa a tutela dei marchi ed è altresì coperto da Copyright. Fatti salvi gli utilizzi strettamente personali,&nbsp;<strong>non è consentito copiare, alterare, distribuire, pubblicare e/o utilizzare i contenuti del presente Sito</strong>&nbsp;senza preventiva e specifica autorizzazione del Titolare del Sito&nbsp;ed, in ogni caso, con espresso obbligo di citarne la fonte.</p>
        <h3>Privacy</h3>
        <p>Nessun&nbsp;<strong>dato personale</strong>&nbsp;sarà richiesto ai visitatori per l’accesso al Sito. Laddove i dati personali fossero richiesti per la fruizione di specifici servizi e/o per dare riscontro ad eventuali richieste di contatto, i dati personali forniti dall’utente saranno trattati nel rispetto del D.Lgs. 30 giugno 2003, n. 196 (“Codice in materia di protezione dei dati personali”) e con modalità idonee a garantirne la riservatezza e la sicurezza, come da specifica informativa che viene resa all’utente nell’apposita privacy policy del Sito.</p>
        <h3>Cookies</h3>
        <p>Per i dettagli in merito all’uso dei cookies si rimanda all’apposita <a href="https://criptosos.it/cookie-policy/" data-type="URL" data-id="https://criptosos.it/cookie-policy/" target="_blank" rel="noreferrer noopener">cookie policy</a>.</p>
        <h3>Legge applicabile</h3>
        <p>Le condizioni contenute nella presente nota legale sono regolate dalla legge italiana. Il Titolare del Sito&nbsp;si riserva il diritto di modificare i contenuti del Sito&nbsp;e delle presenti note legali in qualsiasi momento e senza alcun preavviso.</p>
        </Main>
    </Stack>
  </Layout>
)